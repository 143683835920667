import React from 'react'
import "./Reason.css"


// import gym1 from "../../Asset/gym1.png"
// import gym2 from "../../Asset/gym2.png"
import four from "../../Asset/four.jpg"
// import five from "../../Asset/one.png"
import new1 from "../../Asset/new.jpg"
import new2 from "../../Asset/new2.jpg"
import new3 from "../../Asset/new3.jpg"
import sign from "../../Asset/sign.png"
const Reason = () => {
  return (
    <div className="Reasons" id='reasons'>
<div className="left-r">
    <img src={new1} alt="" />
    <img src={new2} alt="" />
    <img src={four} alt="" />
    <img src={new3} alt="" />
</div>
<div className="right-r">
<span>Some Reasons</span>
<div>
    <span className='stroke-text'>
Kio </span>
<span>
 Choose Us?</span> </div>
   
   <div className='details-r'>
   <div>
    <img src={sign} alt="" style={{width:'15px', height:'15px'}}/>
   <span> HELOOOOOO We are te best </span></div>
   <div><img src={sign} alt="" style={{width:'15px', height:'15px'}} />
   <span>WE Proves it many Time</span></div>
   <div><img src={sign} alt="" style={{width:'15px', height:'15px'}} />
   <span>We Also Work on Mental Health As well as Physical Health</span></div>
   <div><img src={sign} alt="" style={{width:'15px', height:'15px'}} />
   <span>And we Remain te best</span></div>
    </div>
    </div>
    </div>
  )
}

export default Reason
